/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

// @mui material components
import Container from "@mui/material/Container";

import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import paperSubmission from "assets/documents/Pertanika-Paper-Instruction-to-Authors-(2024).docx";

function CallForPapers() {
  return (
    // prettier-ignore
    <BaseLayout
      title="Call For Papers"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/CallForPapers" },
        { label: "Call For Papers" },
      ]}
    >

      <MKBox component="section" py={3}>
        <Container>
        <MKTypography variant="body3">
          Click <a href={paperSubmission}>HERE</a> to download paper submission guideline.
        </MKTypography>
        <br/> <br/>

        <MKTypography 
          variant="body3" 
          textGradient
          align='justify'>
    In today&#39;s rapidly evolving global business landscape and innovative
    business technology, the fields of management and marketing have
    become increasingly interdependent and crucial for organizational
    success. As businesses navigate complex challenges and seize
    emerging opportunities, the need for innovative strategies, data-driven
    insights, and cross-functional collaboration has never been more
    paramount.<br/><br/>
    This event brings together a diverse community of experts from around
    the world to address the most pressing issues and cutting-edge
    developments in the realms of management and marketing.<br/><br/>
    By fostering an interdisciplinary exchange of ideas and best practices,
    the International Conference on Management and Marketing aims to
    inspire innovation, drive academic research, and contribute to the
    advancement of the management and marketing disciplines on a global
    scale. We invite you to be a part of this dynamic and enriching
    experience and to join us in shaping the future of these critical business
    functions.<br/><br/>

    Authors are invited to submit papers in the management and
    marketing areas, but are not limited to the following tracks: <br/>

    <MKTypography  variant="body3"
            textGradient>
                  <ol>
                    <li>
                      Human Resource Management
                    </li>
                    <li>
                      Leadership and Organizational Development
                    </li>
                    <li>
                      Strategic Management
                    </li>
                    <li>
                      Entrepreneurship
                    </li>
                    <li>
                      Consumer Behaviour 
                    </li>
                    <li>
                      Accounting. Finance & Economics
                    </li>
                    <li>
                      Business Intelligence
                    </li>
                    <li>
                      Financial Analytics
                    </li>                 
                    <li>
                      Digital Transformation
                    </li>
                    <li>
                      Tourism & Hospitality Management
                    </li>
                    <li>
                      Business Management
                    </li>
                    <li>
                      Marketing Management
                    </li>
                    <li>
                      Marketing Analytics
                    </li>
                    <li>
                      Digital Marketing
                    </li>
                    <li>
                      Ethics, and Corporate Social Responsibility
                    </li>
                    <li>
                      ESG & Sustainability
                    </li>
                    <li>
                      AI Marketing
                    </li>
                  </ol>                            
                </MKTypography>
    </MKTypography>

    <MKTypography variant="body3">
        All accepted and registered papers of ICMaM2024 will be published in the Pertanika Journal of Social Science and Humanities Proceedings <b>(Approved) </b>
    </MKTypography>
        </Container>
      </MKBox>

    </BaseLayout>
  );
}

export default CallForPapers;
