/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

// @mui material components
import Container from "@mui/material/Container";

import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Box from "@mui/material/Box";

// const styles = {
//   text:{
//     color: 'blue'
//   }
// }

function SubmissionGuidelines() {
  return (
    // prettier-ignore
    <BaseLayout
      title="Submission Guidelines"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/SubmissionGuidelines" },
        { label: "Submission Guidelines" },
      ]}
    >

      <MKBox component="section" py={3}>
        <Container>

        <MKTypography 
          variant="body3" 
          textGradient
          align='justify'>
            ICMaM 2024 accepts only high-quality papers for its conference proceedings, which are subject to a thorough and objective review process of at least two (2) 
          independent reviewers. Authors are invited to submit original unpublished manuscripts that demonstrate current research on one of the ICMaM 2024 topics of interest. 
          All submissions will be handled electronically. Papers with high similarity scores are subject to be rejected. Authors are invited to electronically submit original 
          research contributions in English. The full paper submission can be done online at:  
          <a style={{color: 'red' }} href="https://edas.info/newPaper.php?c=32657" target="_blank" rel="noreferrer">
             <b> https://edas.info/newPaper.php?c=32657</b>
          </a> 
        </MKTypography> <br/><br/>

        <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
        <MKTypography 
          variant="body3" 
          textGradient
          align='justify'>
            {/* Refer the video below for the tutorial how to create EDAS account:   */}
            Refer this video for the tutorial for file submission guidelines:  
          <a style={{color: 'red' }} href="https://www.youtube.com/watch?v=Wif1fGCj_xQ" target="_blank" rel="noreferrer">
             <b> Click HERE</b>
          </a> 
        </MKTypography>
        <br/>
        <MKTypography 
          variant="body3" 
          textGradient
          align='justify'>
            {/* Refer the video below for the tutorial on how to submit paper:   */}
            Refer this video for the step-by-step registration guide:
          <a style={{color: 'red' }} href="https://www.youtube.com/watch?v=T47H4KqVu1g" target="_blank" rel="noreferrer">
             <b> Click HERE</b>
          </a> 
        </MKTypography> 
        </Box>
        
        <br/>

        <MKTypography 
          variant="body3" 
          textGradient
          align='justify'>
           

        </MKTypography>

        <MKTypography 
          variant="body3" 
          textGradient
          align='justify'>
            All accepted and registered papers of ICMaM2024 will be published in the Pertanika Journal of Social Science and Humanities Proceedings <b>(Approved) </b>
            <br/><br/>
            <u>Manuscript Format</u><br/>

            All submitted papers should be in the form of .doc, .docx or .pdf file. Authors are invited to electronically submit original research contributions in English. <br/>

        <ol type="1">
          <li>The paper should be submitted in one-column format with 1.5 line spacing throughout. Authors are advised to use Times New Roman 12-point font and MS Word format.</li>
          <li>Manuscript Structure: the manuscripts, in general, should be organized in the following order:</li>   
          <ul>
            <li>The length of submitted manuscripts should not exceed 6 pages.</li>
            <li>Title: the title should not exceed 60 characters, counting letters and spaces.</li>
            <li>Author(s) and Corresponding author&apos;s information: the corresponding author must be clearly indicated with a superscripted asterisk symbol (*). The names of the 
              authors should be named in full without academic titles. Multiple authors with different addresses must indicate their respective addresses separately by superscript 
              numbers.</li>
            <li>The abstract should be in one paragraph and less than 200 words. Keywords should be four to eight keywords in alphabetical order must be 
              provided to describe the content of the manuscript.</li>
            <li>A manuscript should be prepared with the headings Introduction, Materials and Methods, Results and Discussions, Conclusions, Acknowledgements, References 
              (APA 7th ed. format), and Supplementary data (if any) in this order. The Literature Review may be part of or separated from the Introduction.</li>
          </ul>   
        </ol>  
       
        
        <a style={{color:"red"}}><b>Submission deadline: 17th September 2024</b></a><br/><br/>
        
        <u>Paper Publications in Journal:</u><br/>

        <ol type="1">
          <li>All accepted and registered papers of ICMaM 2024 will be published in “Pertanika Proceeding” Pertanika Journal of Social Sciences and Humanities (Scopus Index)  
            <a href="http://www.pertanika.upm.edu.my/pjssh/introduction" target="_blank" rel="noreferrer"> http://www.pertanika.upm.edu.my/pjssh/introduction</a> </li>
        </ol>

        </MKTypography>
        </Container>
      </MKBox>

    </BaseLayout>
  );
}

export default SubmissionGuidelines;
