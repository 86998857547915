/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Images
import speaker1 from "assets/images/keynote-speakers/sahol-hamid.jpg";
import speaker2 from "assets/images/keynote-speakers/joseph-hair.jpg";
import speaker3 from "assets/images/Devi-Premnath-2.png";
import speaker4 from "assets/images/keynote-speakers/jo-ann.jpg";
import speaker5 from "assets/images/keynote-speakers/piyush-sharma.jpg";
import speaker6 from "assets/images/keynote-speakers/nurul-indarti.jpg";
import speaker7 from "assets/images/keynote-speakers/norlia-ahmad.jpg";
import speaker8 from "assets/images/keynote-speakers/jasmine-begum.jpg";
import speaker9 from "assets/images/Hajah-Mahuran-Saro-Dato-Haji-Sariki.png";
import speaker10 from "assets/images/keynote-speakers/prof-yolanda.jpg";

function KeynoteSpeakers() {
  return (
    // prettier-ignore
    <BaseLayout
      title="Keynote Speakers"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeakers" },
        { label: "Keynote Speakers" },
      ]}
    >

    <Container>
      <Row>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker1} />
                <Card.Body>
                    <Card.Title>Professor Emeritus Tan Sri Dato&apos; Sri Ir Dr Sahol Hamid Bin Abu Bakar</Card.Title>
                    <Card.Text>
                        Vice-Chancellor, UNITAR International University, Malaysia
                    </Card.Text>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>

        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>

            </Card>
        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker2} />
                <Card.Body>
                    <Card.Title>Prof. Joseph F. Hair</Card.Title>
                    <Card.Text>
                        University of South Alabama, USA
                    </Card.Text>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>

        </Col>
      
      </Row>
    
      <Row>
      <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker3} />
                <Card.Body>
                <Card.Title>Prof. Devi Premnath</Card.Title>
                <Card.Text>
                    Jansons School of Business, India
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>

        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker4} />
                <Card.Body>
                <Card.Title>Prof. Jo Ann Ho</Card.Title>
                <Card.Text>
                    Universiti Putra Malaysia, Malaysia
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>

        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker5} />
                <Card.Body>
                <Card.Title>Prof. Piyush Sharma</Card.Title>
                <Card.Text>
                    Curtin University, Australia
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>
        </Col>
        
      </Row>
      <Row>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker6} />
                <Card.Body>
                <Card.Title>Prof. Nurul Indarti</Card.Title>
                <Card.Text>
                    Universitas Gadjah Mada, Indonesia
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>
        </Col>

        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker7} />
                <Card.Body>
                <Card.Title>Prof. Norlia Ahmad</Card.Title>
                <Card.Text>
                    Kwansei Gakuin University, Japan
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>
        </Col>

        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker8} />
                <Card.Body>
                <Card.Title>Hon. Prof. Dr Jasmine Begum</Card.Title>
                <Card.Text>
                    Microsoft,Malaysia
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>
        </Col>

      </Row>

      <Row>
      <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker9} />
                <Card.Body>
                    <Card.Title>Hajah Mahuran Saro Dato’ Haji Sariki</Card.Title>
                    <Card.Text>
                        TalentCorp
                    </Card.Text>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>

        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>

            </Card>
        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker10} />
                <Card.Body>
                    <Card.Title>Prof. Dr Yolanda Masnita Siagian</Card.Title>
                    <Card.Text>
                        Universitas Trisakti
                    </Card.Text>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>

        </Col>
      </Row>
    </Container>

    
        
    
    </BaseLayout>
  );
}

export default KeynoteSpeakers;
