import React from "react";
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import Box from "@mui/material/Box";

// prettier-ignore
const YoutubeEmbed = () => (

    <MKBox component="section" py={3}>
        <Container>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <div className="video-responsive">
                    <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/Wif1fGCj_xQ`}
                    frameBorder="0"
                    // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    />
                </div>
                Refer the video above for the tutorial for file submission guidelines.
            </Box>

            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <div className="video-responsive">
                    <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/T47H4KqVu1g`}
                    frameBorder="0"
                    // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    />
                </div>
                Refer the video above for the step-by-step registration guide.
            </Box>            
            
            <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
                <Grid item xs={12} md={4} display="flex">
                    <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
                    
                    <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
                </Grid>
            </Grid>
        </Container>
    </MKBox>
);

// prettier-ignore
YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;

// import React from "react";
// import PropTypes from "prop-types";
// // @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// // Material Kit 2 React components
// import MKBox from "components/MKBox";

// import Box from "@mui/material/Box";

// // prettier-ignore
// const YoutubeEmbed = ({ embedId }) => (

//     <MKBox component="section" py={3}>
//         <Container>
//             <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
//                 <div className="video-responsive">
//                     <iframe
//                     width="853"
//                     height="480"
//                     src={`https://www.youtube.com/embed/${embedId}`}
//                     frameBorder="0"
//                     // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                     title="Embedded youtube"
//                     />
//                 </div>
//                 Refer the video above for the tutorial for file submission guidelines.
//             </Box>

//             <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
//                 <Grid item xs={12} md={4} display="flex">
//                     <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />

//                     <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
//                 </Grid>
//             </Grid>
//         </Container>
//     </MKBox>
// );

// // prettier-ignore
// YoutubeEmbed.propTypes = {
//   embedId: PropTypes.string.isRequired
// };

// export default YoutubeEmbed;
