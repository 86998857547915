/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import { Table } from "reactstrap";

function Fees() {
  return (
    // prettier-ignore
    <BaseLayout
      title="Fees"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/SubmissionGuidelines" },
        { label: "Fees" },
      ]}
    >

    <MKBox component="section" py={3}>
        <Container>
       
        <Table
    bordered
    hover
    responsive
    striped
    >
    <thead>
        <tr>
            <th rowSpan="2">
                Category
            </th>  
            
            <th colSpan="2" style={{textAlign:"center"}}>
                Fees
            </th>
        {/* <tr>
                <th scope="row">
                    
                </th>
                <td style={{textAlign:"center"}}>
                    Online (USD)
                </td>
                <td style={{textAlign:"center"}}>
                    Physical (USD)
                </td>  
            </tr> */}
        </tr>
    </thead>
   
    <tbody>  
    {/* <tr>
    <td rowSpan="2">Data Spanning Two Rows</td>
    
  </tr>   */}
        <tr>
            <th >
                
            </th>
            <td style={{textAlign:"center"}}>
                <b>Online (RM)</b>
            </td>
            <td style={{textAlign:"center"}}>
                <b>Physical (RM)</b>
            </td>  
        </tr>
        <tr>
            <th scope="row">
                Participant (Presenter - Non-Student)
            </th>
            <td style={{textAlign:"center"}}>
                500 (USD107)
            </td>
            <td style={{textAlign:"center"}}>
                800 (USD170)
            </td>  
        </tr>
        <tr>
            <th scope="row">
                Participant (Presenter - Student)
            </th>
            <td style={{textAlign:"center"}}>
                400 (USD85)
            </td>   
            <td style={{textAlign:"center"}}>
                600 (USD128)
            </td>     
        </tr>
        <tr>
            <th scope="row">
                Attendees(Non-Presenters)
            </th>
            <td style={{textAlign:"center"}}>
                0
            </td>   
            <td style={{textAlign:"center"}}>
                100 (USD22)
            </td>       
        </tr>
    </tbody>
    </Table>
    <a style={{color:"red"}}>*Fees include proceeding fees</a>
       
        </Container>
    </MKBox>

    
   
    </BaseLayout>
  );
}

export default Fees;
